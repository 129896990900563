<template>
	<b-modal id="selecao-cliente"
			 :static="true"
			 title="Selecionar cliente cadastrado..."
			 :size="clientes ? 'xl' : 'md'"
			 @hide="reset">
		<div class="data-field">
			<label for="selecionar-cliente-search">Buscar cliente</label>
			<b-container class="p-0">
				<b-row align-h="center">
					<b-col>
						<input id="selecionar-cliente-search"
							   type="text"
							   ref="selecao-cliente-search"
							   placeholder="Nome, CPF/CNPJ, Telefone ou E-mail"
							   v-model="search"
							   class="w-100"
							   @keydown.enter="!buscandoClientes && search?.trim() && buscarClientes()"
							   @input="error = null"
							   :disabled="buscandoClientes" />
					</b-col>
					<b-col cols="12" md="auto" class="pl-0 py-1">
						<i @click="!buscandoClientes && search?.trim() && buscarClientes()"
						   :class="`fas fa-search fa-fw cursor-${buscandoClientes || !search?.trim() ? 'default text-secondary' : 'pointer'}`"></i>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div v-if="clientes">
			<div class="font-weight-bold text-small mb-2">Resultados para "{{activeSearch}}"</div>
			<div class="tabela-resultados-clientes app-scroll-custom">
				<b-table ref="tabela-selecao-cliente"
						 :fields="fields"
						 :items="clientes"
						 selectable
						 selected-variant="primary"
						 striped
						 select-mode="single"
						 @row-selected="selecionar"
						 class="text-medium">
					<template #cell(Inclusao)="data">
						{{moment(data.value).format("DD/MM/YYYY HH:MM")}}
					</template>
				</b-table>
			</div>
			<center v-if="clientes.length == 0 && !buscandoClientes" class="text-secondary text-medium">
				Nenhum cliente encontrado para "{{activeSearch}}".
			</center>
			<center v-if="buscandoClientes" class="text-medium">
				<b-spinner small class="text-cliente"></b-spinner>
			</center>
		</div>
		<center v-if="error" class="text-danger text-medium">
			<hr />
			{{error}}
		</center>
		<template #modal-footer="{ cancel }">
			<b-button variant="light"
					  @click="cancel()"
					  class="rounded-0">
				Cancelar
			</b-button>
			<b-button variant="success"
					  @click="confirmar"
					  class="rounded-0"
					  :disabled="clienteId == null">
				Confirmar
			</b-button>
		</template>
	</b-modal>
</template>

<script>
	import axios from "axios";
	import moment from "moment";

	export default {
		name: "ModalSelecaoCliente",
		data() {
			return {
				fields: [
					{ key: "Nome", sortable: true },
					{ key: "CnpjCpf", label: "CPF/CNPJ", sortable: true },
					{ key: "Telefone", sortable: true },
					{ key: "Email", label: "E-mail", sortable: true },
					{ key: "Atendimentos", label: "Qnt. Atendimentos", sortable: true },
					{ key: "Inclusao", label: "Data de Cadastro", sortable: true },
				],
				clienteId: null,
				search: null,
				activeSearch: null,
				buscandoClientes: false,
				error: null,
				clientes: null,
				retornarClienteId: null
			};
		},
		methods: {
			confirmar() {
				this.retornarClienteId(this.clienteId);
				this.$bvModal.hide("selecao-cliente");
			},
			selecionarCliente() {
				this.$bvModal.show("selecao-cliente");
				return new Promise(resolve => this.retornarClienteId = resolve);
			},
			buscarClientes() {
				this.error = null;
				this.clientes = [];
				this.buscandoClientes = true;
				this.activeSearch = this.search;
				axios.get(`api/cliente/consulta-geral`, {
					params: {
						query: this.search
					}
				}).then(response => {
					this.buscandoClientes = false;
					if (!response || response.status != 200) {
						this.clientes = null;
						this.error = "Houve um erro na consulta de clientes. Tente mais tarde.";
						return;
					}
					this.clientes = response.data;
				});
			},
			reset() {
				this.clienteId = null;
				this.search = null;
				this.activeSearch = null;
				this.buscandoClientes = false;
				this.error = null;
				this.clientes = null;
				this.retornarClienteId = null;
			},
			selecionar(items) {
				this.clienteId = items && items.length == 0 ? null : items[0].Id;
			}
		},
		created() {
			this.moment = moment;
		}
	}
</script>

<style scoped>
	.text-small {
		font-size: 12px;
	}

	.text-medium {
		font-size: 14px;
	}

	.tabela-resultados-clientes {
		max-height: 60vh;
		overflow: auto;
	}
</style>